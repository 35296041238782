import React, { useEffect, useState } from 'react';
import '../../config/style.css';
import Ambulytics from '../../assets/images/logos/ambulytics_logo.png';
import { useHistory } from 'react-router';
import { UserAPIService } from '../../config/api-service';
import RequestDemoEmailInput from '../common/RequestDemoEmailInput/RequestDemoEmailInput';
import Features from '../common/Features/Features';
import MaterialUITheme from '../../config/material-ui-theme';
import useScript from '../../config/useScript';
import { TextField, Typography, useMediaQuery, useTheme } from '@mui/material';
import { useAppContext } from '../../context';


export const SignupComponent = () => {

    const theme = useTheme()
    const lg = useMediaQuery('(min-width:1480px)')
    const md = useMediaQuery(theme.breakpoints.up('md'))

    const { ip } = useAppContext()
    useScript(ip)

    return (
        <MaterialUITheme>
            <div style={{ maxWidth: lg ? "700px" : md ? "550px" : "350px", margin: "auto", padding: "40px 0" }}>
                <img src={Ambulytics} alt="Dataconnector" height={120} />
                <p style={{ fontSize: lg ? "53px" : md ? "43px" : "28px", margin: "0 0 20px 0" }}>Access your data today</p>
                <Typography style={{ fontSize: lg ? "33px" : md ? "23px" : "20px", margin: "-20px 0 30px 0" }}>Enter your email to start your 14-day <br />free trial. No credit card required!</Typography>
                <div className="home-container main-container" style={{ padding: lg ? "0 50px" : md ? "0 25px" : "0 10px", }}>
                    <RequestDemoEmailInput />
                </div >
                <div style={{ marginTop: "100px" }}>
                    <Features />
                </div>
            </div>
        </MaterialUITheme>

    )
}
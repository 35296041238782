import { Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import React from "react";
import "../../config/style.css";
import { ButtonFilled } from "../utils/button";

const { REACT_APP_MARKETING_SITE } = process.env;

const Footer = () => {
  const theme = useTheme();
  const xs = useMediaQuery(theme.breakpoints.up("xs"));
  const sm = useMediaQuery(theme.breakpoints.up("sm"));
  const md = useMediaQuery(theme.breakpoints.up("md"));

  const iconStyle = {
    mr: 1,
    fontSize: xs ? "14px" : "18px",
    padding: "2px",
    border: "1px solid #fff",
    backgroundColor: "#fff",
    color: "var(--ambulytics-green)",
    borderRadius: "7px",
  };

  return (
    <div style={{ backgroundColor: "var(--ambulytics-green)", color: "#fff" }}>
      <div className="footer">
        <Grid container style={{ alignItems: sm ? "flex-start" : "center" }}>
          {/* <Grid item xs={12} sm={4} md={1} lg={1} style={{ display: md ? "block" : "none" }}>
                    </Grid> */}
          {/* <Grid item xs={6} sm={6} md={2} lg={2} style={{ textAlign: sm ? "left" : "center", marginBottom: "15px", paddingLeft: sm ? "55px" : 0 }}>
                        <img src={logo} alt="" width={100} />
                    </Grid> */}
          <Grid
            item
            xs={6}
            sm={6}
            md={4}
            lg={4}
            className="footer-link"
            style={{ textAlign: sm ? "left" : "center", marginBottom: "15px", paddingLeft: sm ? "55px" : 0 }}
          >
            <a href={`/register`} target="_blank" rel="noreferrer">
              <ButtonFilled size="medium" borderColor="#fff" fontColor="#fff" fillColor="transparent">
                Schedule demo
              </ButtonFilled>
            </a>
          </Grid>
          <Grid
            item
            xs={6}
            sm={6}
            md={4}
            lg={4}
            className="footer-link"
            style={{ textAlign: sm ? "left" : "center", marginBottom: "15px", paddingLeft: sm ? "55px" : 0 }}
          >
            <Typography style={{ textTransform: "uppercase", fontSize: sm ? "16px" : "13px" }}>Product</Typography>
            <a href={`/product`} className="footer-link" target="_blank" rel="noreferrer">
              <Typography style={{ fontSize: sm ? "16px" : "13px" }}>Overview</Typography>
            </a>
            {/* <a href={`/pricing`} className='footer-link' target='_blank' rel="noreferrer">
                            <Typography style={{ fontSize: sm ? "16px" : "13px" }}>Pricing</Typography>
                        </a> */}
          </Grid>
          {/* <Grid
            item
            xs={6}
            sm={6}
            md={3}
            lg={3}
            className="footer-link"
            style={{ textAlign: sm ? "left" : "center", marginBottom: "15px", paddingLeft: sm ? "55px" : 0 }}
          >
            <Typography style={{ textTransform: "uppercase", fontSize: sm ? "16px" : "13px" }}>Company</Typography>
            <Box style={{ display: "flex", justifyContent: sm ? "flex-start" : "center" }}>
              <a
                href="https://twitter.com/Data_Connector"
                className="footer-link"
                target="_blank"
                rel="noreferrer"
              >
                <TwitterIcon sx={iconStyle} />
              </a>
              <a
                href="https://www.facebook.com/people/Data-Connector/100087590074644/"
                className="footer-link"
                target="_blank"
                rel="noreferrer"
              >
                <FacebookIcon sx={iconStyle} />
              </a>
              <a
                href="https://www.youtube.com/channel/UCEnfdp6oD53m5hKhdPm5Zrw"
                className="footer-link"
                target="_blank"
                rel="noreferrer"
              >
                <YouTubeIcon sx={iconStyle} />
              </a>
              <a
                href="https://www.linkedin.com/company/dataconnector"
                className="footer-link"
                target="_blank"
                rel="noreferrer"
              >
                <LinkedInIcon sx={iconStyle} />
              </a>
            </Box>
            <a href={`/`} className="footer-link" target="_blank" rel="noreferrer">
              <Typography style={{ fontSize: sm ? "16px" : "13px" }}>Contact Us</Typography>
            </a>
          </Grid> */}
          <Grid
            item
            xs={6}
            sm={6}
            md={4}
            lg={4}
            className="footer-link"
            style={{ textAlign: sm ? "left" : "center", marginBottom: "15px", paddingLeft: sm ? "55px" : 0 }}
          >
            <Typography style={{ textTransform: "uppercase", fontSize: sm ? "16px" : "13px" }}>Legal</Typography>
            <a href={`/terms`} className="footer-link" target="_blank" rel="noreferrer">
              <Typography style={{ fontSize: sm ? "16px" : "13px" }}>Terms</Typography>
            </a>
            <a href={`/privacy`} className="footer-link" target="_blank" rel="noreferrer">
              <Typography style={{ fontSize: sm ? "16px" : "13px" }}>Privacy</Typography>
            </a>
          </Grid>
          {/* <Grid item xs={12} className='footer-link' >
                        
                    </Grid> */}
        </Grid>
      </div>
      <div style={{ textAlign: sm ? "left" : "center" }}>
        <Typography style={{ fontWeight: 500, fontSize: sm ? "16px" : "13px", paddingLeft: sm ? "25px" : 0 }}>
          &copy; Ambulytics. {new Date().getFullYear()}. All Rights Reserved.
        </Typography>
      </div>
    </div>
  );
};

export default Footer;

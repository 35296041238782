import { useState } from 'react';
import MaterialUITheme from '../../config/material-ui-theme';
import useScript from '../../config/useScript';
import { Box, FormControl, Grid, InputAdornment, InputLabel, MenuItem, Paper, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from '@mui/material';
import SettingsSuggestIcon from '@mui/icons-material/SettingsSuggest';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import DataDisplay from './DataDisplay';
import DataSourceList from './DataSourceList';
import LeftChartComponent from './LeftChartComponent';
import RightChartComponent from './RightChartComponent';
import CenterChartComponent from './CenterChartComponent';
import { useAppContext } from '../../context';
import useCaptureEvent from '../../config/useCaptureEvent';



const columns = [
  { id: 'name', label: 'Name', minWidth: 170, color: "var(--ambulytics-green)" },
  { id: 'code', label: 'ISO\u00a0Code', minWidth: 100, color: "var(--ambulytics-green)" },
  {
    id: 'population',
    label: 'Population',
    minWidth: 170,
    align: 'right',
    format: (value) => value.toLocaleString('en-US'),
    color: "var(--ambulytics-green)"
  },
  {
    id: 'size',
    label: 'Size\u00a0(km\u00b2)',
    minWidth: 170,
    align: 'right',
    format: (value) => value.toLocaleString('en-US'),
    color: "var(--ambulytics-green)"
  },
  {
    id: 'density',
    label: 'Density',
    minWidth: 170,
    align: 'right',
    format: (value) => value.toFixed(2),
    color: "var(--ambulytics-green)"
  },
];

function createData(name, code, population, size) {
  const density = population / size;
  return { name, code, population, size, density };
}

const rows = [
  createData('India', 'IN', 1324171354, 3287263),
  createData('China', 'CN', 1403500365, 9596961),
  createData('Italy', 'IT', 60483973, 301340),
  createData('United States', 'US', 327167434, 9833520),
  createData('Canada', 'CA', 37602103, 9984670),
  createData('Australia', 'AU', 25475400, 7692024),
  createData('Germany', 'DE', 83019200, 357578),
  createData('Ireland', 'IE', 4857000, 70273),
  createData('Mexico', 'MX', 126577691, 1972550),
  createData('Japan', 'JP', 126317000, 377973),
  createData('France', 'FR', 67022000, 640679),
  createData('United Kingdom', 'GB', 67545757, 242495),
  createData('Russia', 'RU', 146793744, 17098246),
  createData('Nigeria', 'NG', 200962417, 923768),
  createData('Brazil', 'BR', 210147125, 8515767),
];

export default function Product() {

  const [dashboard, setDashboard] = useState(10);
  const [provider, setProvider] = useState(10);
  const [location, setLocation] = useState(10);
  const [payor, setPayor] = useState(10);
  const [cohort, setCohort] = useState(10);
  const [fiscalYear, setFiscalYear] = useState(10);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  const { ip } = useAppContext()
  useScript(ip)
  const captureEvent = useCaptureEvent()

  const style = {
    width: { xs: "275px", md: "130px" },
    m: 1,
    "& .MuiButtonBase-root": {
      backgroundColor: "#fff",
      borderRadius: "0",
      px: "2px"
    },
    "& .MuiInputAdornment-root": {
      margin: 0
    },
    "& .MuiInputBase-input": {
      textAlign: "left",
      pl: 1,
      color: "var(--ambulytics-green)",
      display: "flex",
      alignItems: "flex-end",
      backgroundColor: "#fff"
    },
    '& label': {
      color: 'var(--ambulytics-green)',
    },
    '& label.Mui-focused': {
      color: 'var(--ambulytics-green)',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: 'var(--ambulytics-green)',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'var(--ambulytics-green)',
      },
      '&:hover fieldset': {
        borderColor: 'var(--ambulytics-green)',
      },
      '&.Mui-focused fieldset': {
        borderColor: 'var(--ambulytics-green)',
      },
    },
    '& .MuiSvgIcon-root': {
      fill: "var(--ambulytics-green)"
    }
  }

  return (
    <MaterialUITheme>
      <Box sx={{ p: 2, backgroundColor: "#F4F6F7" }}>
        <TextField
          size="small"
          select
          value={dashboard}
          label="Dashboard"
          onChange={(event) => setDashboard(event.target.value)}
          onBlur={() => captureEvent(ip, "Changed Dashboard", `Selected ${dashboard}`)}
          sx={{ ...style, width: { xs: "275px", md: "300px", xl: "350px" }, mr: { md: 2, xl: 3 } }}
          defaultValue={10}
        >
          <MenuItem value={10} sx={{ display: "flex", alignItems: "center" }}><SettingsSuggestIcon sx={{ color: "var(--ambulytics-green)", pr: 1 }} /> <span>Provider Productivity</span> </MenuItem>
          <MenuItem value={20} sx={{ display: "flex", alignItems: "center" }}><SettingsSuggestIcon sx={{ color: "var(--ambulytics-green)", pr: 1 }} /> <span>Client Productivity</span> </MenuItem>
          <MenuItem value={30} sx={{ display: "flex", alignItems: "center" }}><SettingsSuggestIcon sx={{ color: "var(--ambulytics-green)", pr: 1 }} /> <span>User Productivity</span> </MenuItem>
        </TextField>
        <TextField
          size="small"
          select
          value={provider}
          label="Provider"
          onChange={(event) => setProvider(event.target.value)}
          onBlur={() => captureEvent(ip, "Changed Provider", `Selected ${provider}`)}
          sx={style}
          defaultValue={10}
        >
          <MenuItem value={10}>All</MenuItem>
          <MenuItem value={20}>Many</MenuItem>
          <MenuItem value={30}>Some</MenuItem>
        </TextField>
        <TextField
          size="small"
          select
          value={location}
          label="Location"
          onChange={(event) => setLocation(event.target.value)}
          onBlur={() => captureEvent(ip, "Changed Location", `Selected ${location}`)}
          sx={style}
          defaultValue={10}
        >
          <MenuItem value={10}>All</MenuItem>
          <MenuItem value={20}>Many</MenuItem>
          <MenuItem value={30}>Some</MenuItem>
        </TextField>
        <TextField
          size="small"
          select
          value={payor}
          label="Payor"
          onChange={(event) => setPayor(event.target.value)}
          onBlur={() => captureEvent(ip, "Changed Payor", `Selected ${payor}`)}
          sx={style}
          defaultValue={10}
        >
          <MenuItem value={10}>Medicare</MenuItem>
          <MenuItem value={20}>Many</MenuItem>
          <MenuItem value={30}>Some</MenuItem>
        </TextField>
        <TextField
          size="small"
          select
          value={cohort}
          label="Cohort"
          onChange={(event) => setCohort(event.target.value)}
          onBlur={() => captureEvent(ip, "Changed Cohort", `Selected ${cohort}`)}
          sx={style}
          defaultValue={10}
        >
          <MenuItem value={10}>Diabetes</MenuItem>
          <MenuItem value={20}>Many</MenuItem>
          <MenuItem value={30}>Some</MenuItem>
        </TextField>
        <TextField
          size="small"
          select
          value={fiscalYear}
          label="Fiscal Year"
          onChange={(event) => setFiscalYear(event.target.value)}
          onBlur={() => captureEvent(ip, "Changed Fiscal Year", `Selected ${fiscalYear}`)}
          sx={style}
          defaultValue={10}
        >
          <MenuItem value={10}>2022</MenuItem>
          <MenuItem value={20}>2021</MenuItem>
          <MenuItem value={30}>2020</MenuItem>
        </TextField>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            disableFuture
            label="Start Date"
            openTo="day"
            value={startDate}
            onChange={(newValue) => {
              setStartDate(newValue);
            }}
            onClose={() => captureEvent(ip, "Changed Start Date", `Selected ${startDate}`)}
            renderInput={(params) => <TextField size="small" sx={style} {...params} />}
          />
          <DatePicker
            disableFuture
            label="End Date"
            openTo="day"
            value={endDate}
            onChange={(newValue) => {
              setEndDate(newValue);
            }}
            onClose={() => captureEvent(ip, "Changed End Date", `Selected ${endDate}`)}
            renderInput={(params) => <TextField size="small" sx={style} {...params} />}
          />
        </LocalizationProvider>
        <Box sx={{ my: 3 }}>
          <DataDisplay titleOne={"135"} titleTwo={"Providers"} />
          <DataDisplay titleOne={"675"} titleTwo={"cFTE"} />
          <DataDisplay titleOne={"75.04%"} titleTwo={"Completion"} />
          <DataDisplay titleOne={"23"} titleTwo={"Days/Month"} />
          <DataDisplay titleOne={"9.7"} subTitleOne={"-2.14"} color={"#E96058"} titleTwo={"Visit/Day"} />
          <DataDisplay titleOne={"1.5"} titleTwo={"wRVUs/Visit"} />
          <DataDisplay titleOne={"330k"} color={"#E96058"} titleTwo={"wRVUs"} />
          <DataDisplay titleOne={"$3.15M"} titleTwo={"Opportunity"} />
          <DataDisplay titleOne={"$1.25M"} color={"var(--ambulytics-green)"} titleTwo={"Realized"} />
        </Box>
        <Grid container spacing={1}>
          <Grid item xs={12} md={3}>
            <Paper sx={{ height: 370, alignItems: "center" }}>
              <Box sx={{ textAlign: "left", p: 1 }}>
                <Typography sx={{ color: "var(--ambulytics-green)", fontWeight: 700, p: 0, m: 0 }}>wRVU Goal Trend</Typography>
              </Box>
              <LeftChartComponent />
            </Paper>
          </Grid>
          <Grid item xs={12} md={6}>
            <Paper sx={{ height: 370, alignItems: "center", overflow: "auto" }}>
              <Box sx={{ textAlign: "left", p: 1 }}>
                <Typography sx={{ color: "var(--ambulytics-green)", fontWeight: 700, p: 0, m: 0 }}>wRVU Calculator</Typography>
                <Typography sx={{ fontWeight: 300, fontSize: "14px" }}>Adjust sliders to model how changes to current performance will affect annualized wRVUs.</Typography>
              </Box>
              <CenterChartComponent />
            </Paper>
          </Grid>
          <Grid item xs={12} md={3}>
            <Paper sx={{ height: 370, alignItems: "center" }}>
              <Box sx={{ textAlign: "left", p: 1 }}>
                <Typography sx={{ color: "var(--ambulytics-green)", fontWeight: 700, p: 0, m: 0 }}>wRVU Daily Average</Typography>
              </Box>
              <RightChartComponent />
            </Paper>
          </Grid>
        </Grid>
        {/* <Box>
          <Paper sx={{ p: 2, display: "inline-block", textAlign: "left", maxHeight: 400, maxWidth: 600 }}>
            
          </Paper>
        </Box> */}

        <Box sx={{ mt: 2, display: "flex", flexDirection: { xs: "column", md: "row" }, justifyContent: { xs: "center", md: "space-between" } }}>
          <Paper sx={{ width: '100%', overflow: 'hidden', mr: { sm: 2 } }}>
            <TableContainer sx={{ maxHeight: 300 }}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {columns.map((column) => (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        sx={{ minWidth: column.minWidth, color: column.color, py: 1 }}
                      >
                        {column.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows
                    .map((row) => {
                      return (
                        <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                          {columns.map((column) => {
                            const value = row[column.id];
                            return (
                              <TableCell key={column.id} align={column.align} sx={{ py: 1 }}>
                                {column.format && typeof value === 'number'
                                  ? column.format(value)
                                  : value}
                              </TableCell>
                            );
                          })}
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>

          <Paper sx={{ minWidth: 290, maxHeight: 300, overflow: "auto", textAlign: "left", padding: 1, mt: { xs: 2, sm: 0 } }}>
            <Typography sx={{ color: "var(--ambulytics-green)", weight: 300 }}>Check Data Source</Typography>
            {['Provider List', 'Visits', 'Cohorts', 'Calender', 'wRVU Budget Goal', 'Location'].map(sourceName => <DataSourceList key={sourceName} sourceName={sourceName} />)}
          </Paper>
        </Box>
      </Box>
    </MaterialUITheme>
  )
}


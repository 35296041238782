import React from "react";
import HomeComponent from "../components/home";
import LoginComponent from '../components/login';
import ProductPage from "../components/product/Product";
import { SignupComponent } from "../components/sign-up";
import RegisterCompanyIndex from "../components/sign-up/register-company-index";
import NavigationComponent from '../components/navigation';
import LegalComponent from "../components/legal";
import ResetPasswordComponent from "../components/reset-password";
import ChangeSystemPassword from "../components/change-system-password";
import UnsubscribeEmail from "../components/unsubscribe/UnsubscribeEmail";
import TermsComponent from "../components/terms";
import PrivacyComponent from "../components/privacy";
import FreeTrialLogin from "../components/home/FreeTrialLogin";
import { BrowserRouter, Route, Routes } from "react-router-dom";
// import SourceSetupComponent from "../components/home/SourceSetup";
// import PricingPage from "../components/pricing/PricingPage";
// import DestinationSetupComponent from "../components/home/DestinationSetup";
// import CompanyComponent from "../components/company";
// import DemoComponent from "../components/demo";
// import ForgotPasswordComponent from "../components/forgot";
// import SalesforceConnector from "../components/home/SalesforceConnector";
// import QuickbooksConnector from "../components/home/QuickbooksConnector";

export default function RouterComponent() {

    return (<>
        {/* <Router>
            < NavigationComponent />
            <Switch>
                <Route exact path="/">
                    <HomeComponent />
                </Route>
                <Route exact path="/source-connector/:name">
                    <SourceSetupComponent />
                </Route>
                <Route exact path="/destination-connector/:name">
                    <DestinationSetupComponent />
                </Route>
                <Route exact path="/login">
                    <LoginComponent />
                </Route>
                <Route exact path="/login-setup">
                    <FreeTrialLogin />
                </Route>
                <Route exact path="/forgot">
                    <ForgotPasswordComponent />
                </Route>
                <Route exact path="/reset">
                    <ResetPasswordComponent />
                </Route>
                <Route exact path="/change-password">
                    <ChangeSystemPassword />
                </Route>
                <Route exact path="/product">
                    <ProductPage />
                </Route>
                <Route exact path="/pricing">
                    <PricingPage />
                </Route>
                <Route exact path="/company">
                    <CompanyComponent />
                </Route>
                <Route exact path="/demo">
                    <DemoComponent />
                </Route>
                <Route exact path="/register">
                    <SignupComponent />
                </Route>
                <Route exact path="/register-company">
                    <RegisterCompanyIndex />
                </Route>
                <Route exact path="/legal">
                    <LegalComponent />
                </Route>
                <Route exact path="/terms">
                    <TermsComponent />
                </Route>
                <Route exact path="/privacy">
                    <PrivacyComponent />
                </Route>
                <Route exact path="/unsubscribe">
                    <UnsubscribeEmail />
                </Route>
            </Switch>
        </Router> */}
        <BrowserRouter>
            < NavigationComponent />
            <Routes>
                <Route path="/" element={<HomeComponent />} />
                <Route path="/product" element={<ProductPage />} />
                <Route path="/login" element={<LoginComponent />} />
                <Route path="/login-setup" element={<FreeTrialLogin />} />
                <Route path="/reset" element={<ResetPasswordComponent />} />
                <Route path="/change-password" element={<ChangeSystemPassword />} />
                <Route path="/register" element={<SignupComponent />} />
                <Route path="/register-company" element={<RegisterCompanyIndex />} />
                <Route path="/legal" element={<LegalComponent />} />
                <Route path="/terms" element={<TermsComponent />} />
                <Route path="/privacy" element={<PrivacyComponent />} />
                <Route path="/unsubscribe" element={<UnsubscribeEmail />} />
            </Routes>
        </BrowserRouter>
    </>)
}
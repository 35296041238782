import React from 'react';
import { Box, Typography } from '@mui/material';
import DataConnectorLogo from '../../assets/images/logos/main_inverse.png'

const DataSourceList = ({ sourceName }) => {
    return (
        <Box sx={{ display: "flex", alignItems: "center" }}>
            <img src={DataConnectorLogo} alt="Data Connector" width="40px" />
            <Typography sx={{ fontWeight: 300, pl: 1 }}>{sourceName}</Typography>
        </Box>
    );
};

export default DataSourceList;
import * as React from 'react';
import { Alert, AlertTitle, Snackbar } from '@mui/material';
import { useAlertContext } from '../../../context/alert';

export default function AlertsComponent() {
    const {
        open,
        setOpen,
        severity,
        alertMessage,
        alertTitle
    } = useAlertContext();
    const handleClose = () => {
        setOpen(false);
    }

    const getAlertActionItem = () => {
        switch (alertMessage) {
            case "The email is already registered.":
                return <a href="/login" className="alert-link">Sign In</a>;
            default:
                return null;
        }
    }
    return (
        <Snackbar
            open={open}
            autoHideDuration={6000}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            onClose={handleClose}>
            <Alert severity={severity} variant="filled">
                <AlertTitle>{alertTitle}</AlertTitle>
                {alertMessage}
                {getAlertActionItem()}
            </Alert>
        </Snackbar>
    );
}
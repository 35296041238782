import React from 'react';
import { createTheme, ThemeProvider } from '@mui/material';

const theme = createTheme({
    palette: {
        primary: {
            main: '#56b2e3',
            contrastText: '#fff'
        },
        secondary: {
            main: '#00bfff',
        },
    },
    typography: {
        fontFamily: ['"quicksand-vf"', 'sans-serif'].join(','),
        fontSize: 14,
        allVariants: {
            color: 'var(--dark-grey)'
        }
    }
})

export default function MaterialUITheme({ children }) {
    return (
        <ThemeProvider theme={theme}>
            {children}
        </ThemeProvider>
    );
}

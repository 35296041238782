import { useEffect, useRef, useState } from 'react';
import { deviceDetect, getUA } from 'react-device-detect';
import { useLocation } from 'react-router-dom'
import { AmbulyticsMarketingEventLogAPIService } from './api-service';

const useScript = (ip) => {

    const [deviceDetails,] = useState({ ...deviceDetect(getUA) });
    const location = useLocation();
    const calledAPI = useRef(true)

    useEffect(() => {
        const script = document.createElement('script');

        if (ip && calledAPI.current) {
            AmbulyticsMarketingEventLogAPIService.addAmbulyticsMarketingEventLog({
                IPAddressTxt: ip,
                DeviceDsc: deviceDetails.isMobile ? `${deviceDetails.vendor} ${deviceDetails.model}` : `${deviceDetails.browserName} ${deviceDetails.osName}`,
                EventLogTypeDsc: "Navigation",
                EventLogDsc: `Visited ${location.pathname} route.`,
                EventPageUrlTxt: location.pathname
            })
            calledAPI.current = false
        }

        script.src = "https://www.googletagmanager.com/gtag/js?id=G-W967ZFK3DG";
        script.async = true;

        window.dataLayer = window.dataLayer || [];
        function gtag() { window.dataLayer.push(arguments); }
        gtag('js', new Date());

        gtag('config', 'G-W967ZFK3DG');

        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        }
    }, [deviceDetails.browserName, deviceDetails.isMobile, deviceDetails.model, deviceDetails.osName, deviceDetails.vendor, ip, location.pathname]);
};

export default useScript;




import React from 'react';
import { Card } from '@mui/material';
import Ambulytics from '../../../assets/images/logos/ambulytics_logo.png';

export default function RaisedFormComponent({ children, pageLabel }) {
    return (
        <div className="main-container margin-5">
            <Card raised={true} className='login'>
                <div className="top" style={{ textAlign: 'center' }}>
                    <img src={Ambulytics} className="main-logo-image" alt="Logo" />
                </div>
                <h1 className='text' style={{ color: '#686868' }}>{pageLabel}</h1>

                {children}
            </Card>
        </div>
    )
}

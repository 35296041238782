import React from 'react';
import { createTheme, ThemeProvider } from '@mui/material';

export default function MUIThemeProvider({ children }) {
    const theme = createTheme({
        palette: {
            primary: {
                main: '#00bfff',
            }
        }
    });

    return (
        <ThemeProvider theme={theme}>
            {children}
        </ThemeProvider>
    );
}
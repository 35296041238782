import { Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import Lottie from "lottie-react";

export default function RowComponent({ header, description, src, flexDirection = '' }) {
  const theme = useTheme()
  const md = useMediaQuery(theme.breakpoints.up('md'))

  return (
    <Grid
      container
      sx={{
        maxWidth: 1200,
        m: '0 auto',
        // marginTop: "40px",
        pl: { md: "50px", xs: "15px" },
        textAlign: 'left',
        alignItems: 'center',
        justifyContent: { md: 'flex-start', xs: 'center' },
        flexDirection
      }}
    >
      <Grid item md={6} sm={12} xs={12} sx={{ pl: { md: flexDirection ? "80px" : 0 } }}>
        <Typography component={'h2'} variant="h5" sx={{ fontWeight: 600, color: "#000", fontSize: { xs: "20px", sm: "24px", md: "30px" } }}>
          {header}
        </Typography>
        {/* <Typography style={{ fontWeight: 500 }}>
          {description}
        </Typography> */}
        <Typography style={{ fontWeight: 500, fontSize: "14px" }}>
          <span style={{ color: "#000", fontWeight: 600 }}>Direct data access.</span> Point your dashboard directly from the source with scheduled or live refreshes.
        </Typography>
        <Typography style={{ fontWeight: 500, fontSize: "14px" }}>
          <span style={{ color: "#000", fontWeight: 600 }}>Real time alerting.</span> Get emailed alerts when something needs your attention.
        </Typography>
        <Typography style={{ fontWeight: 500, fontSize: "14px" }}>
          <span style={{ color: "#000", fontWeight: 600 }}>Access from anywhere.</span> Whether you are on your computer or mobile device, always have your data available.
        </Typography>
      </Grid>
      <Grid item md={6} sm={12} xs={12}>
        <img src={src} alt={header} width="550" style={{ maxWidth: "100%", height: "auto", margin: "10px auto" }} />
      </Grid>
    </Grid>
  )
}
import MenuIcon from "@mui/icons-material/Menu";
import { Button, IconButton } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import AmbulyticsIcon from "../../assets/images/logos/ambulytics_logo.png";
import "../../config/style.css";
import { ConfirmationDialogRaw } from "../common/RequestDemoEmailInput/RequestDemoEmailInput";

export default function TopNavBar() {
  const [openModal, setOpenModal] = useState(false);

  function openDrawer(open = true) {
    var x = document.getElementById("myTopnav");
    if (open && x.className === "topnav") {
      x.className += " responsive change";
    } else {
      x.className = "topnav";
    }
  }

  const useAvoidDidMountEffect = (func, deps) => {
    const didMount = useRef(false);

    useEffect(() => {
      if (didMount.current) func();
      else didMount.current = true;
    }, deps);
  };

  const location = useLocation();
  useAvoidDidMountEffect(() => {
    openDrawer(false);
  }, [location]);

  return (
    <>
      <nav className="topnav" id="myTopnav">
        <div style={{ display: "flex", margin: 0, alignItems: "center" }}>
          <img src={AmbulyticsIcon} className="nav-logo-img" alt="Ambulytics" />
          {/* <h1 className='topNavHeader'>ambulytics</h1> */}
        </div>
        {/* <div className="is-active">{isActive}</div> */}
        <ul className="nav-links">
          <li>
            <NavLink exact to="/" className={({ isActive }) => (isActive ? "active-link" : "nav-link")}>
              Home
            </NavLink>
          </li>
          <li>
            <NavLink to="/product" className={({ isActive }) => (isActive ? "active-link" : "nav-link")}>
              Product
            </NavLink>

            {/* <ProductMegaMenu /> */}
          </li>
          {/* <li>
                        <NavLink to="/pricing" className="nav-link" activeClassName="active-link">
                            Pricing
                        </NavLink>
                    </li> */}
          {/* <li>
                        <NavLink to="/company" className="nav-link" activeClassName="active-link">
                            Company
                        </NavLink>
                    </li> */}
          {/* <li>
                        <NavLink to="/customers" className="nav-link" activeClassName="active-link">
                            Customers
                        </NavLink>
                    </li> */}
          {/* <li>
                        <NavLink to="/company" className="nav-link" onClick={() => setActive("LOG IN")}>
                            Community
                        </NavLink>
                    </li> */}
          {/* <li>
                        <NavLink to="/resources" className="nav-link" activeClassName="active-link">
                            Resources
                        </NavLink>
                    </li> */}
        </ul>
        <div className="nav-2">
          {/* <NavLink to="/login">
                        <ButtonFilled size="medium" borderColor="var(--ambulytics-green)" fontColor="var(--ambulytics-green)" fillColor="transparent">
                            LOGIN
                        </ButtonFilled>
                    </NavLink> */}
          {/* <NavLink to="/"> */}
          {/* <ButtonFilled
              size="medium"
              borderColor="var(--ambulytics-green)"
              fontColor="#fff"
              fillColor="var(--ambulytics-green)"
            >
              SCHEDULE A DEMO
            </ButtonFilled> */}
          <NavLink to="/" style={{ textDecoration: "none" }}>
            <Button
              variant="contained"
              sx={{
                background: "var(--ambulytics-green)",
                color: "#fff",
                ":hover": { background: "var(--ambulytics-green)" },
              }}
              onClick={() => setOpenModal(true)}
            >
              {" "}
              SCHEDULE A DEMO
            </Button>
          </NavLink>
          {/* </NavLink> */}
          <IconButton aria-label="Menu" className="hamburger-menu" onClick={openDrawer}>
            <MenuIcon fontSize="small" />
          </IconButton>
        </div>
        <ConfirmationDialogRaw keepMounted open={openModal} setOpen={setOpenModal} value={{
          email: "",
          FirstNM: "",
          LastNM: "",
          Phone: "",
        }} />
      </nav>
    </>
  );
}

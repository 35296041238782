import React from "react";
// import { Typography } from '@material-ui/core';
// import { useTheme } from '@material-ui/core/styles'
// import useMediaQuery from '@material-ui/core/useMediaQuery'
import { Box, Grid, Paper, Typography } from "@mui/material";
import dashboard from "../../../assets/images/ambulytics/dashboard.png";
import decision from "../../../assets/images/ambulytics/decision.png";
import dollar from "../../../assets/images/ambulytics/dollar.png";
import insight from "../../../assets/images/ambulytics/insight.png";
import patient_flow from "../../../assets/images/ambulytics/patient_flow.png";
import provider_productivity from "../../../assets/images/ambulytics/provider_productivity.png";
import reporting from "../../../assets/images/ambulytics/reporting.png";
import target from "../../../assets/images/ambulytics/target.png";
import toggle from "../../../assets/images/ambulytics/toggle.png";
import RequestDemoEmailInput from "../RequestDemoEmailInput/RequestDemoEmailInput";
import RowComponent from "../RowComponent/RowComponent";

const CardComponent = ({ icon, description }) => {
  return (
    <Paper
      className="insight-card"
      elevation={1}
      sx={{ height: "240px", width: "130px", p: 2, m: 1, display: "inline-block", borderRadius: 0 }}
    >
      <img
        src={icon}
        alt="Data Connector"
        // style={{ height: 30, verticalAlign: "middle", padding: "10px 0 40px 0" }}
        className="insight-card-icon"
      />
      {description}
    </Paper>
  );
};

const Offers = () => {
  return (
    <Box sx={{ my: 2 }}>
      <Box sx={{ backgroundColor: "#f8f8f8bd", py: 4 }}>
        <Typography
          sx={{ fontSize: { xs: "20px", sm: "24px", md: "30px" }, mb: "30px", color: "#000", fontWeight: 600 }}
        >
          Upgrade your reporting and dashboards
        </Typography>
        <Grid
          container
          // spacing={1}
          sx={{ maxWidth: "800px", margin: "auto", "& .MuiGrid-item": { p: 0 } }}
        >
          <Grid item xs={12} sm={6} md={4}>
            <Box sx={{ maxWidth: "210px", mx: "auto" }}>
              <img src={insight} alt="Data Connector" style={{ height: 60, verticalAlign: "middle" }} />
              <Typography sx={{ color: "#000", fontWeight: 600, fontSize: "18px", my: 1, textAlign: "justify" }}>
                Gain insights faster
              </Typography>
              <Typography component={"p"} sx={{ textAlign: "justify", fontSize: "15px" }}>
                Pre-built dashboards give instant access to actionable insights.
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Box sx={{ maxWidth: "210px", mx: "auto", my: { xs: 2, md: 0 } }}>
              <img src={target} alt="Data Connector" style={{ height: 60, verticalAlign: "middle" }} />
              <Typography sx={{ color: "#000", fontWeight: 600, fontSize: "18px", my: 1, textAlign: "justify" }}>
                Hit goals more often
              </Typography>
              <Typography component={"p"} sx={{ textAlign: "justify", fontSize: "15px" }}>
                Choose from industry standard goals or have the flexibility to create your own goals. Instantly
                know if you are on track and what you need to hit those goals.
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Box sx={{ maxWidth: "210px", mx: "auto" }}>
              <img src={decision} alt="Data Connector" style={{ height: 60, verticalAlign: "middle" }} />
              <Typography sx={{ color: "#000", fontWeight: 600, fontSize: "18px", my: 1, textAlign: "justify" }}>
                Make informed decisions
              </Typography>
              <Typography component={"p"} sx={{ textAlign: "justify", fontSize: "15px" }}>
                Have all the data you need to make decisions.
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Box sx={{ maxWidth: "875px", m: "auto", py: 6 }}>
        <RowComponent header="Real-time dashboards" src={dashboard} flexDirection="row-reverse" />
      </Box>

      <Box sx={{ backgroundColor: "#f8f8f8bd", py: 4 }}>
        <Typography
          sx={{ fontSize: { xs: "20px", sm: "24px", md: "30px" }, mb: "30px", color: "#000", fontWeight: 600 }}
        >
          Focus on the insights you need
        </Typography>
        {/* <Box sx={{ display: { md: "flex" }, justifyContent: { md: "center" } }}> */}
        <CardComponent
          icon={toggle}
          description={
            <Typography sx={{ textAlign: "left" }}>
              Toggle on and off different modules, paying for only what you use <br />
              <br />
            </Typography>
          }
        />
        <CardComponent
          icon={patient_flow}
          description={
            <Typography sx={{ textAlign: "left" }}>
              Improve patient flow with all the right information at your fingertips <br />
              <br />
              <br />
            </Typography>
          }
        />
        <CardComponent
          icon={provider_productivity}
          description={
            <Typography sx={{ textAlign: "left" }}>
              Set and track your goals to see improvements <br />
              <br />
              <br />
              <br />
            </Typography>
          }
        />
        <CardComponent
          icon={reporting}
          description={
            <Typography sx={{ textAlign: "left" }}>
              Automate your reporting needs with easy-to-use scheduling tools <br />
              <br />
              <br />
            </Typography>
          }
        />
        <CardComponent
          icon={dollar}
          description={
            <Typography sx={{ textAlign: "left" }}>
              Increase revenue and decrease expenses with your data tied directly to the bottom line
            </Typography>
          }
        />
        {/* </Box> */}
      </Box>

      <Box sx={{ maxWidth: "700px", m: "auto", px: { xs: "10px", md: "25px", lg: "50px" } }}>
        <Typography
          sx={{
            fontSize: { xs: "20px", sm: "24px", md: "30px" },
            mt: "30px",
            mb: "-20px",
            color: "#000",
            fontWeight: 600,
          }}
        >
          Schedule your free demo today!
        </Typography>
        <RequestDemoEmailInput />
      </Box>
    </Box>
  );
};

export default Offers;

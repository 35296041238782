import React from 'react';
import useScript from '../../config/useScript';
import { useAppContext } from '../../context';


const PrivacyComponent = () => {
    const { ip } = useAppContext()
    useScript(ip)
    return (
        <div style={{ textAlign: "left", padding: "0px 10px" }}>
            <p style={{ textAlign: "center" }}><strong>DATA PRIVACY AND USE POLICY<br /></strong></p>
            <p>Last updated: February 28, 2022</p>
            <p>Ambulytics, LLC (&ldquo;Ambulytics&rdquo;) is dedicated to providing informative and useful information about its products and services through online, mobile, and other software and related systems and platforms, as well as any in-person, telephone, or other offline locations or through other aspects of Ambulytics &rsquo;s business (collectively, the &ldquo;System&rdquo;), including, without limitation, on and through the website located at www.ambulytics.com and/or other portion of the System designated by Ambulytics&nbsp;&nbsp;from time to time.&nbsp;&nbsp;As a part of the operation of the System, Ambulytics gathers certain data about users. &nbsp;</p>
            <p>This Data Privacy and Use Policy (&ldquo;Policy&rdquo;) applies to the System and governs data collection and usage at, on and through the System.&nbsp;&nbsp;Please read this Policy carefully.&nbsp;&nbsp;Each time you use, browse, or otherwise access any part of the System, you signify your acceptance of the then-current Policy, including, without limitation, the then current terms found elsewhere on the System.&nbsp;&nbsp;If you do not agree with this Policy, you are not authorized to access or otherwise use the website, mobile application, or any other part of the System. &nbsp;</p>
            <p><strong>Data Collected</strong></p>
            <p>You acknowledge that any personal information provided or collected through the System is voluntarily provided to Ambulytics from you.&nbsp;&nbsp;As you navigate any part of the System, you will find that there are several online forms or other locations which you can use to request information regarding a specific product or service.&nbsp;&nbsp;In order for Ambulytics to effectively enable the System and allow access to certain content or otherwise facilitate Ambulytics &rsquo;s business, Ambulytics does collect and use certain data.&nbsp;&nbsp;Two types of data are collected: (a) personal data, such as names, company names, postal addresses, e-mail addresses, telephone numbers, occupation, dates of birth, age, gender, financial information, credit card information, bank account information, social security information, product and/or service preferences (&ldquo;Personal Data&rdquo;); and (b) non-personal aggregate or traffic data, such as your IP address, which web pages or portions of the System you are visiting, the order in which the areas are visited, unique device identifier, time zone, which hyperlinks or functions are being &ldquo;clicked&rdquo;, how many users are visiting or accessing the System, vehicles sites viewed, domain server, location-based or real-time geographic location information, type of computer or device, and type of web browser (&ldquo;Traffic Data&rdquo; and together with Personal Data, the &ldquo;Collected Data&rdquo;).</p>
            <p>You acknowledge that you are responsible for any data you input into the System.&nbsp;&nbsp;Ambulytics will not collect or retain such data for any other reason beyond its purpose to provide the product and services.</p>
            <p><strong>Collection and Usage</strong></p>
            <p>Personal Data is gathered when users provide or authorize Personal Data to or through the System, for example, when requesting information, participating in an online survey, inputting content into the System, verifying user credentials, purchasing products or services, or utilizing any other Ambulytics product or service through the System</p>
            <p>Traffic Data is tracked and gathered when you and other users visit or access the System.&nbsp;&nbsp;While gathering Traffic Data may not collect Personal Data, it may provide information on the geographical location from which users access the System.&nbsp;&nbsp;Traffic Data generally does not identify individual users.&nbsp;&nbsp;Instead, the Traffic Data identifies System users as a whole, including demographic information, preferences in content and advertising, quality of service, and web and application traffic patterns. &nbsp;</p>
            <p>Ambulytics&nbsp;&nbsp;may, in Ambulytics &rsquo;s sole and absolute discretion, release and disclose the Collected Data to affiliated or contracted persons, associations, or other entities of whatsoever nature, including, without limitation, strategic alliances and advertising affiliated persons and/or entities (the &ldquo;Related Parties&rdquo;) or to any law enforcement agency, court, or other governmental authority to: (a) help Ambulytics&nbsp;&nbsp;respond to your requests for information, goods or services and/or otherwise fulfill a user&rsquo;s lawful request; (b) respond or otherwise comply with subpoenas, court orders, or other edicts of the law or legal process; (c) protect and defend Ambulytics &rsquo;s rights and property; or (d) to protect the personal safety of Ambulytics &rsquo;s users, Ambulytics &rsquo;s employees, or the public. &nbsp;</p>
            <p>For instance, Ambulytics&nbsp;&nbsp;may use your Personal Data to: (1) send you information about Ambulytics&nbsp;&nbsp;and its products, updates, services, important notices, special offers, and/or upcoming promotions; (2) send you information about the Related Party&rsquo;s products and services; (3) conduct surveys about your opinion of Ambulytics , its staff, performance, products and services or the System; (4) verify your qualifications for certain promotions, special offers, products and services; (5) bill you for any products and services purchased through the System via a credit card, electronic, or other payment; and (6) customize, improve, and/or tailor your experience on the System by displaying content that Ambulytics thinks you might be interested in according to your preferences.&nbsp; &nbsp;</p>
            <p>Where appropriate, in Ambulytics &rsquo;s sole and absolute discretion, Ambulytics may provide notice to you.&nbsp;&nbsp;For example, Ambulytics believes it may be necessary to share information with or without notice in order to investigate, prevent, or take action regarding illegal activities, suspected fraud, situations involving potential threats to the physical safety of any person, violations of the website terms of use, or as otherwise required by law. &nbsp;</p>
            <p>Ambulytics&nbsp;&nbsp;may, in Ambulytics &rsquo;s sole and absolute discretion, release and disclose the Collected Data to any persons, associations, or other entities of whatsoever nature not affiliated with Ambulytics&nbsp;&nbsp;(the &ldquo;Non-Affiliates&rdquo;) for the purpose of facilitating your instructions, requests and/or registrations for products or services provided by Ambulytics or the Non-Affiliates (including, without limitation, customer support, information technology support, credit card or other electronic payment processing and personal profile services) when the transfer of such Collected Data is necessary to provide you with such products or services.&nbsp;&nbsp;By selecting, instructing, requesting, reserving and/or registering for such products or services from Ambulytics or the Non-Affiliates on or through the System, you consent to the release of your Collected Data to the Non-Affiliates.&nbsp;&nbsp;Ambulytics shall use reasonable efforts to provide the Non-Affiliates access only to the Personal Data necessary to assist in responding to your instructions, requests, and/or registrations for such products or services and cause such Non-Affiliates to use your Personal Data for these limited purposes.&nbsp;</p>
            <p>Ambulytics&nbsp;&nbsp;may also disclose, release, transfer, sell, or otherwise convey any Collected Data to prospective or actual buyers, sellers or persons and/or entities related to such actions by Ambulytics , including, without limitation, Ambulytics&nbsp;&nbsp;may transfer information about you if Ambulytics&nbsp;&nbsp;is acquired by or merge with, or otherwise sell any portion of Ambulytics &rsquo;s assets or equity to any other company. &nbsp;</p>
            <p>You consent to any and all such conveyances of the Collected Data regardless of your location and your consent includes any and all such conveyances outside of your location, including, without limitation, in the United States and in other countries and territories.</p>
            <p><strong>Your Rights:</strong></p>
            <p>Under certain circumstances, you have the rights under data protection laws in relation to your Personal Data.&nbsp;&nbsp;These include the right to:</p>
            <p><br /></p>
            <p>Request access to your Personal Data.</p>
            <p>Request correction of your Personal Data.</p>
            <p>Request erasure of your Personal Data.</p>
            <p>Object to processing of your Personal Data.</p>
            <p>Request restriction of processing your Personal Data.</p>
            <p>Request transfer of your Personal Data.</p>
            <p>Right to withdraw consent.</p>
            <p><br /></p>
            <p style={{ wordWrap: "break-word" }}>If you wish to exercise any of the rights set out above, please email us at ambulytics@xantie.com.&nbsp;&nbsp;We may need to request specific information from you to help us confirm your identity and ensure your right to access your Personal Data (or to exercise any of your other rights).&nbsp;&nbsp;This is a security measure to ensure that Personal Data is not disclosed to any person who has no right to receive it.&nbsp;&nbsp;We may also contact you to ask for further information in relation to your request to speed up our response.</p>
            <p><br /></p>
            <p><strong>Opt Out</strong></p>
            <p style={{ wordWrap: "break-word" }}>From time to time, you may receive periodic mailings, telephone calls or e-mails from Ambulytics with news or other information on Ambulytics &rsquo;s products and/or services.&nbsp;&nbsp;If at any time you wish to stop receiving emails or mailings from Ambulytics&nbsp;&nbsp;please send us an email to ambulytics@xantie.com with the phrase &ldquo;Privacy Opt-out: Ambulytics&nbsp;&nbsp;Mailings&rdquo; in the subject line, or write to us at 95 N 490 W American Fork, Utah 84003, and we will remove you from Ambulytics &rsquo;s mailing list.&nbsp;&nbsp;Alternatively, for e-mail communications, you may opt out of receiving such communications by following the unsubscribe instructions set forth at the bottom of most e-mail messages from Ambulytics.</p>
            <p style={{ wordWrap: "break-word" }}>In the event that Ambulytics wishes to transfer or release your Personal Data to a third person in a manner not set forth in this Policy, Ambulytics shall inform you prior to such transfer or release and provide you with the ability to opt out of such transfer or release of Personal Data.&nbsp;&nbsp;Please contact Ambulytics&nbsp;&nbsp;at ambulytics@xantie.com if you have any questions as to how to opt out of any specific disclosures, or any marketing or other programs.</p>
            <p><strong>Cookies and Related Files</strong></p>
            <p>Ambulytics , through the System, may place small files, called &lsquo;cookies&rsquo; onto your computer or electronic device, as well as related files, such as web beacons, pixel tags, or other means.&nbsp;&nbsp;Ambulytics&nbsp;&nbsp;may use such files or Internet protocol addresses for a number of reasons, such as record keeping purposes when you visit visits, identifying you as a unique user, simplifying the process of recording personal information (such as enabling users to not have to enter names, addresses or passwords repetitively), allowing Ambulytics&nbsp;&nbsp;to better understand how users use the System and help Ambulytics&nbsp;&nbsp;tailor the System or marketing message to better match needs and interests.&nbsp; &nbsp;</p>
            <p>You may be able to program your browser to not to accept cookies or other files, but if you do, you may be unable to access some parts of the System or some parts of the System may not work properly.</p>
            <p>While on the System, you may receive cookies from third party advertisers or promotional entities.&nbsp;&nbsp;These cookies are outside the control of Ambulytics , are not subject to the Policy, and Ambulytics&nbsp;&nbsp;is not responsible for such third-party cookies.</p>
            <p><strong>Links to Third-Party Sites</strong></p>
            <p>The System may contain links to other websites or media not operated or controlled by Ambulytics.&nbsp;&nbsp;These links are provided as a convenience to the visitors of the System.&nbsp;&nbsp;Since Ambulytics does not operate or control linked websites or media, Ambulytics cannot be responsible for the personal information collection and use policies of such linked websites or media.&nbsp;&nbsp;Therefore, you should review the privacy policy of linked websites or media prior use to use or giving personal information.</p>
            <p>For example, chat rooms, forums, message boards, links to third party websites or mobile applications (including social media websites and mobile applications), and/or news groups may be available through the System.&nbsp;&nbsp;While Ambulytics will only use and disclose the Collected Data as set forth herein, any information disclosed in these areas is or may be deemed public information and you must exercise caution and discretion when deciding to disclose personal and non-personal information.</p>
            <p>Additionally, please keep in mind that if you directly disclose Personal Data or personally sensitive data through your home page, linked sites, or other public forums, this information may be collected and used by others.&nbsp;&nbsp;Ambulytics encourages all users to review the privacy statements of websites and/or mobile applications you choose to link to from the System so that you understand how those third parties collect, use and share information about you and/or your electronic devices.&nbsp;&nbsp;Ambulytics is not responsible for the data collection, data usage, privacy statements or content on other websites, applications or other solutions.</p>
            <p><strong>Measures to Safeguard Children</strong></p>
            <p>Ambulytics &rsquo;s products and services are not directed or intended for the use of children or users under the age of 18.&nbsp;&nbsp;Ambulytics encourages parents and guardians to spend time with their children online and to be fully familiar with the websites and other software visited by their children.&nbsp;&nbsp;At various places on the System, Ambulytics may ask users whether they are 18 years of age or older or of proper legal age.&nbsp;&nbsp;Ambulytics relies on users to be truthful in responding to these questions.&nbsp;&nbsp;Ambulytics does not knowingly collect or maintain personal information from children under 13 years of age.&nbsp;&nbsp;If Ambulytics learns that personal information of a child under 13 years of age has been collected, Ambulytics will take steps to delete the information as soon as possible. If you are under 18 years of age or the proper legal age, you are not allowed to use or access the System at any time or in any manner and any such use is unauthorized. &nbsp;</p>
            <p>Except where required in accordance with law, law enforcement agency, court, or other governmental authority, Ambulytics does not intentionally solicit Personal Data from individuals under 18 years of age.&nbsp;&nbsp;A parent or guardian whose child provided Personal Data to Ambulytics without the parent&rsquo;s or guardian&rsquo;s consent should immediately notify Ambulytics of such disclosure in writing to the contact provided below.&nbsp;&nbsp;Ambulytics, upon receipt of such written notice, shall take reasonable measures to remove such disclosed information from Ambulytics &rsquo;s database and/or notify the appropriate authority.</p>
            <p><strong>Security&nbsp;</strong></p>
            <p>Ambulytics has implemented security measures to help protect the Personal Data.&nbsp;&nbsp;For example: (a) Ambulytics stores Personal Data on servers that are in one or more physical locations that are monitored and accessible by a limited authorized set of individuals and (b) certain Collected Data is obtained through a web form and transmitted using secure socket layer (SSL) or other secure technology as may be determined by Ambulytics from time to time.&nbsp;&nbsp;Although Ambulytics has implemented such security measures to help protect the Personal Data, the Personal Data may still be accessed, altered, disclosed and/or otherwise used in an unauthorized manner and as such, Ambulytics cannot guarantee the security of your Personal Data from unauthorized access, alteration, disclosure and/or other impermissible uses and Ambulytics is not responsible for such unauthorized access or other impermissible uses.</p>
            <p><br /></p>
            <p><strong>Changes to this Policy</strong></p>
            <p>Ambulytics reserves the right to change or modify this Policy, including, without limitation, to reflect company and user feedback or changes in applicable law.&nbsp;&nbsp;Please revisit this Policy from time to time for changes and updates.&nbsp;&nbsp;If Ambulytics&nbsp;&nbsp;refuses access to you, Ambulytics&nbsp;&nbsp;may provide you with the reasons for such refusal upon request; provided, however, that Ambulytics&nbsp;&nbsp;reserves the right to refuse to give a reason for such refusal, including, without limitation, where such information contains references to other individuals, such information cannot be disclosed for legal, security or commercial reasons, or the information is subject to attorney-client or litigation privilege.&nbsp;&nbsp;Your continued use of the System constitutes your consent to any and all such changes.</p>
            <p><strong>Governing Law/Claims</strong></p>
            <p>This Policy, the System, and any claim associated with, related to, or arising from the Policy and/or System are governed by the law of the State of Utah within the United States.&nbsp;&nbsp;Any claim associated with, relating to or arising from this Policy or the System shall be brought in a federal or state court in the County of Utah, State of Utah within one year after the claim arises.&nbsp;&nbsp;You hereby affirm your consent to the sole and exclusive jurisdiction of the courts located in the County of Utah, State of Utah as the most convenient and appropriate forum for the resolution of disputes concerning this Policy or System.&nbsp;&nbsp;The System is controlled, operated and administered entirely within the United States.&nbsp;&nbsp;Use of the System from locations outside the United States where these practices are illegal or violate any law is prohibited.</p>
            <p><strong>System Provided &ldquo;As Is&rdquo; and Other Disclaimers</strong></p>
            <p>YOU AGREE THAT THE SYSTEM AND ALL Ambulytics PRODUCTS AND SERVICES ARE PROVIDED &ldquo;AS IS.&rdquo;&nbsp;&nbsp;Ambulytics&nbsp;&nbsp;MAKES NO REPRESENTATIONS, WARRANTIES, OR CONDITIONS OF ANY KIND, EXPRESS OR IMPLIED AND DISCLAIMS ANY AND ALL WARRANTIES, CONDITIONS OR REPRESENTATIONS, WHETHER OR NOT IMPLIED, ORAL OR WRITTEN, WITH RESPECT TO THE Ambulytics&nbsp;&nbsp;SERVICES, Ambulytics&nbsp;&nbsp;PRODUCTS, SYSTEM, OR ANY OF THE TRANSACTIONS REASONABLY CONTEMPLATED BY THE PARTIES PURSUANT TO THIS AGREEMENT INCLUDING, WITHOUT LIMITATION, ANY AND ALL IMPLIED WARRANTIES OR CONDITIONS OF TITLE, NON INFRINGEMENT, MERCHANTABILITY, FITNESS OR SUITABILITY FOR ANY PURPOSE (WHETHER OR NOT Ambulytics&nbsp;&nbsp;KNOWS, HAS REASON TO KNOW, HAS BEEN ADVISED OR IS OTHERWISE, IN FACT, AWARE OF ANY SUCH PURPOSE), WHETHER ALLEGED TO ARISE BY LAW, BY REASON OF CUSTOMER USAGE IN THE TRADE OR BY COURSE OF DEALING&nbsp;</p>
            <p>Ambulytics&nbsp;&nbsp;AND Ambulytics &rsquo;S REPRESENTATIVES SHALL NOT BE LIABLE TO YOU (OR TO ANY PERSON CLAIMING ANY RIGHT, TITLE OR INTEREST DERIVED FROM OR AS SUCCESSOR TO YOUR&nbsp;&nbsp;RIGHT, TITLE AND INTEREST) WHETHER IN TORT (INCLUDING NEGLIGENCE), CONTRACT OR OTHER LEGAL THEORY FOR INCIDENTAL, INDIRECT, CONSEQUENTIAL OR SPECIAL DAMAGES OF ANY KIND INCLUDING, WITHOUT LIMITATION, LOST REVENUES OR PROFITS, LOSS OF BUSINESS, LOSS OR CORRUPTION OF CONTENT, INTERRUPTION OR COMPUTER FAILURE ARISING OUT OF THIS AGREEMENT, IRRESPECTIVE OF WHETHER THE PARTIES HAVE ADVANCE NOTICE OF THE POSSIBILITY OF SUCH DAMAGES.&nbsp;</p>
            <p><strong>Contact Information</strong></p>
            <p style={{ wordWrap: "break-word" }}>Questions about this Policy should be directed to ambulytics@xantie.com or by writing to us at 95 N 490 W American Fork, Utah 84003. &nbsp;Please note that email communications may not be secure. &nbsp;Accordingly, you should not include personal or other sensitive information in your email correspondence to Ambulytics .&nbsp;</p>
        </div>
    );
};

export default PrivacyComponent;
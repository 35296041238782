import axios from "axios";

const { REACT_APP_API_URL } = process.env;

const axiosInstance = axios.create({
  withCredentials: true,
  baseURL: REACT_APP_API_URL + "/api/",
});

const config = {
  headers: {
    "Content-Type": "application/json",
    "Access-Control-Allow-Credentials": true,
  },
};

const ApiService = {
  get: (path) => {
    return axiosInstance.get(path).catch((err) => console.log(err.body, "error"));
  },
  post: (path, payload) => {
    return axiosInstance.post(path, payload, config);
  },
  put: (path, payload) => {
    return axiosInstance.put(path, payload, config);
  },
};

export const UserAPIService = {
  login: (credentials) => {
    return ApiService.post("users/login", credentials);
  },
  register: (credentials) => {
    return ApiService.post("users/register", credentials);
  },
  registerByEmail: (payload) => {
    return ApiService.post("users/register-by-email", payload);
  },
  getHearAboutUsOptions: () => {
    return ApiService.get("user-hear-about-options");
  },
  registerExistingUserCompany: (credentials) => {
    return ApiService.post("users/register-existing-user-company", credentials);
  },
  contact: (form) => {
    return ApiService.post("users/contact", form);
  },
  userDetailsByEmail: (email) => {
    return ApiService.get(`users/by-email?email=${email}`);
  },
  paymentMethod: (payload) => {
    return ApiService.post("users/payment-method", payload);
  },
  forgotPassword: (form) => {
    return ApiService.post("users/forgot", form);
  },
  resetPassword: (form) => {
    return ApiService.post("users/reset-password", form);
  },
  updateTemporaryPassword: (payload) => {
    return ApiService.post("users/new-password", payload);
  },
  getEmailPreference: ({ email, uid }) => {
    return ApiService.get(`email-preference/by-token?email=${email}&uid=${uid}`);
  },
  updateEmailPreference: ({ data, email }) => {
    return ApiService.put(`email-preference/unsubscribe?email=${email}`, data);
  },
};

export const AmbulyticsUserAPIService = {
  registerByEmail: (payload) => {
    return ApiService.post("ambulytics-users/register-by-email", payload);
  },
};

export const AmbulyticsMarketingEventLogAPIService = {
  addAmbulyticsMarketingEventLog: (payload) => {
    return ApiService.post("ambulytics-marketing-event-log/add", payload);
  },
};

export const ConnectorAPIService = {
  getConnectors: () => {
    return ApiService.get("connector/public");
  },
};

export const BillingAPIService = {
  getAverageRowsPerMin: () => {
    return ApiService.get("billing/average-rows-minute");
  },
};

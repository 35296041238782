import React, { useEffect, useState } from 'react';
import '../../config/style.css';
import DataConnectorImage from '../../assets/images/logos/main_inverse.png';
import FreeTrialIcon from '../../assets/images/icons/free_trial.png';
import RegisterCompanyComponent from './register-company';
// import PaymentComponent from './payment';
import PlansComponent from './plans';
import FinalizeComponent from './finalize';
import useScript from '../../config/useScript';
import { Card } from '@mui/material';
import { useAppContext } from '../../context';


export default function RegisterCompanyIndex() {
    const [activeStep, setActiveStep] = useState(1);
    const [emailTXT, setEmailTXT] = useState('');
    const [organizationID, setOrganizationID] = useState();

    const { ip } = useAppContext()
    useScript(ip)

    const getActiveStep = () => {
        switch (activeStep) {
            case 1:
                return (<RegisterCompanyComponent setActiveStep={setActiveStep} emailTXT={emailTXT} setEmailTXT={setEmailTXT} setOrganizationID={setOrganizationID} />);
            case 2:
                return (<PlansComponent setActiveStep={setActiveStep} />);
            case 3:
                //     return (<PaymentComponent setActiveStep={setActiveStep} emailTXT={emailTXT} setEmailTXT={setEmailTXT} organizationID={organizationID} />);
                // case 4:
                return (<FinalizeComponent emailTXT={emailTXT} />)
            default:
                throw new Error("Invalid step action.");
        }
    }

    const stepTitles = ["Register", "Membership Details", "Set up your payment", "Welcome to Data Connector!"]

    return (<>
        <div class="main-container margin-3">
            <div className='free-trial-icon'>
                <img src={FreeTrialIcon} alt="Logo" />
            </div>
            <Card raised={true} className='login' style={{ marginTop: 0 }}>

                <div className="top" style={{ textAlign: 'center' }}>
                    <img src={DataConnectorImage} className="main-logo-image" alt="Logo" />
                </div>

                <h1 className='text' style={{ color: '#686868' }}>{stepTitles[activeStep - 1]}</h1>

                {activeStep !== 4 ? (
                    <h4 className='text' style={{ color: '#686868' }}>STEP {activeStep} OF 3</h4>
                ) : null}
                {getActiveStep()}
            </Card>
        </div>
    </>)
}
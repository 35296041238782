import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import DateBox from "devextreme-react/date-box";
import "devextreme/dist/css/dx.light.css";
import moment from "moment";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { toast } from "react-toastify";
import { AmbulyticsUserAPIService } from "../../../config/api-service";
import useCaptureEvent from "../../../config/useCaptureEvent";
import { useAppContext } from "../../../context";

const RequestDemoEmailInput = () => {
  const [email, setEmail] = useState("");
  const emailReg = /^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+.[a-zA-Z0-9+.]$/;

  const theme = useTheme();
  const md = useMediaQuery(theme.breakpoints.up("md"));

  const [open, setOpen] = React.useState(false);
  const [value, setValue] = React.useState({});

  const { ip } = useAppContext();
  const captureEvent = useCaptureEvent();

  const handleSubmit = async () => {
    setOpen(true);
    setValue({ email });
    captureEvent(ip, "Request Demo", `Clicked Request Demo Button`);
  };

  return (
    <div style={{ display: md && "flex", marginTop: "40px" }}>
      <TextField
        fullWidth
        sx={{ m: "2px", backgroundColor: "#fff", height: "40px" }}
        variant="outlined"
        size="small"
        type="text"
        value={email}
        name="email"
        placeholder="Enter your email address"
        onChange={(e) => setEmail(e.target.value)}
        onBlur={() => captureEvent(ip, "Request Demo", `Entered Email: ${email}`)}
        error={!emailReg.test(email) && email !== ""}
        label={!emailReg.test(email) && email !== "" ? "Invalid Email!" : ""}
      />
      <div style={{ display: "flex", flexDirection: "column" }}>
        <p
          onClick={handleSubmit}
          className="btn btn-primary"
          style={{
            display: "inline-block",
            padding: "7px",
            borderRadius: "3px",
            minWidth: "130px",
            margin: "2px",
            pointerEvents: !emailReg.test(email) && "none",
          }}
        >
          Request a demo
        </p>
      </div>
      <ConfirmationDialogRaw keepMounted open={open} setOpen={setOpen} value={value} />
    </div>
  );
};

export default RequestDemoEmailInput;

export function ConfirmationDialogRaw(props) {
  const { value: valueProp, open, setOpen, ...other } = props;
  const [value, setValue] = React.useState(valueProp);
  const [DateTime, setDateTime] = React.useState(moment(new Date()).format());
  const [emailError, setEmailError] = React.useState("");

  const emailReg = /^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+.[a-zA-Z0-9+.]$/;

  const { ip } = useAppContext();
  const captureEvent = useCaptureEvent();

  React.useEffect(() => {
    if (open) {
      setValue(valueProp);
    }
  }, [valueProp, open]);

  const onClose = async (newValue) => {
    const { data: newData } = await AmbulyticsUserAPIService.registerByEmail(newValue);
    if (newData.EmailTXT) {
      toast.success(`Your demo request was successfully received. Please check your inbox for details`);
      setOpen(false);
      setValue({
        email: "",
        FirstNM: "",
        LastNM: "",
        Phone: "",
      });
    }
  };

  const handleOk = () => {
    if (!value.email) {
      setEmailError("Email address is required");
      return;
    } else if (!emailReg.test(value.email)) {
      return;
    } else {
      onClose({
        ...value,
        RequestedDemoDate: moment(new Date(DateTime)).format(),
        timeZoneOffset: new Date(DateTime).getTimezoneOffset(),
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      });
      setDateTime(moment(new Date()).format());
    }
  };

  const handleChange = (event) => {
    setValue({ ...value, [event.target.name]: event.target.value });
    setEmailError("");
  };

  const handleCloseIcon = () => {
    setOpen(false);
    setValue({
      email: "",
      FirstNM: "",
      LastNM: "",
      Phone: "",
    });
    setEmailError("");
  };

  return (
    <Dialog sx={{ "& .MuiDialog-paper": { width: "80%" } }} maxWidth="sm" open={open} {...other}>
      <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
        <DialogTitle>Schedule your demo today</DialogTitle>

        <CloseIcon onClick={handleCloseIcon} style={{ cursor: "pointer", marginRight: "1rem" }} />
      </div>
      <DialogContent dividers>
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <TextField
            sx={{ mb: 2 }}
            variant="outlined"
            size="small"
            required
            placeholder="Email"
            value={value.email || ""}
            onChange={handleChange}
            onBlur={() => captureEvent(ip, "Request Demo", `Entered Email Address: ${value.email}`)}
            name="email"
            error={emailError !== "" || (!emailReg.test(value.email) && value.email !== "")}
            label={
              emailError ? emailError : !emailReg.test(value.email) && value.email !== "" ? "Invalid Email!" : ""
            }
          />
          <TextField
            sx={{ mb: 2 }}
            variant="outlined"
            size="small"
            placeholder="First Name"
            value={value.FirstNM}
            onChange={handleChange}
            onBlur={() => captureEvent(ip, "Request Demo", `Entered First Name: ${value.FirstNM}`)}
            name="FirstNM"
          />
          <TextField
            sx={{ mb: 2 }}
            variant="outlined"
            size="small"
            placeholder="Last Name"
            value={value.LastNM}
            onChange={handleChange}
            onBlur={() => captureEvent(ip, "Request Demo", `Entered Last Name: ${value.LastNM}`)}
            name="LastNM"
          />
          <TextField
            sx={{ mb: 2 }}
            variant="outlined"
            size="small"
            placeholder="Phone Number"
            value={value.Phone}
            onChange={handleChange}
            onBlur={() => captureEvent(ip, "Request Demo", `Entered Phone Number: ${value.Phone}`)}
            name="Phone"
          />
          <div class="dx-viewport">
            <div className="dx-field">
              <div className="dx-field-value" style={{ width: "100%" }}>
                <DateBox
                  value={DateTime}
                  onValueChanged={(e) => setDateTime(e.value)}
                  onClosed={() => captureEvent(ip, "Request Demo", `Entered Demo Date: ${DateTime}`)}
                  type="datetime"
                />
              </div>
            </div>
          </div>
        </Box>
      </DialogContent>
      <DialogActions>
        <p
          onClick={handleOk}
          className="btn btn-primary"
          style={{
            display: "inline-block",
            padding: "7px",
            borderRadius: "3px",
            minWidth: "130px",
            margin: "2px",
          }}
        >
          SCHEDULE A DEMO
        </p>
      </DialogActions>
    </Dialog>
  );
}

ConfirmationDialogRaw.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  value: PropTypes.string.isRequired,
};

import React, { useState, useEffect } from 'react';
import '../../config/style.css';
import Lottie from "lottie-react";
import MaterialUITheme from '../../config/material-ui-theme';
import Ambulytics from '../../assets/images/logos/ambulytics_logo.png';
import DataconnectorAnimation from '../../assets/images/connector.json';
import CompanyLogos from '../../assets/images/company_logos.png'
import Features from '../common/Features/Features';
import Offers from '../common/Offers/Offers';
import RequestDemoEmailInput from '../common/RequestDemoEmailInput/RequestDemoEmailInput';
import { UserAPIService } from '../../config/api-service';
import useScript from '../../config/useScript';
import { useMediaQuery, useTheme, Divider, Typography } from '@mui/material';
import { useAppContext } from '../../context';



export default function HomeComponent() {
    const theme = useTheme()
    const lg = useMediaQuery('(min-width:1480px)')
    const md = useMediaQuery(theme.breakpoints.up('md'))

    const { ip } = useAppContext()
    useScript(ip)

    return (
        <MaterialUITheme>
            <div style={{ padding: "40px 0" }}>
                <img src={Ambulytics} alt="Ambulytics" height={120} />
                <h1 style={{ fontSize: lg ? "52px" : md ? "42px" : "27px", margin: "-5px 0 0 0", fontWeight: 500 }}>Outcomes-driven ambulatory analytics</h1>
                <div className="home-container main-container" style={{ maxWidth: "800px", margin: "auto", padding: lg ? "0 50px" : md ? "0 25px" : "0 10px", }}>
                    <Typography sx={{ fontSize: { xs: "18px", sm: "22px", md: "24px" }, mb: "10px", color: "#000", fontWeight: 500 }}>Ambulytics transforms your ambulatory data into actionable insights.<br />
                        Modules like patient flow and access, provider productivity, revenue cycle, and more provide you with the data you need to improve the bottom line. Everything you need together.</Typography>
                    <RequestDemoEmailInput />

                    {/* <Lottie
                        style={{ maxWidth: 500, margin: "0 auto" }}
                        animationData={DataconnectorAnimation} />
                    <Features />
                    <Divider style={{ margin: "10px 0" }} />
                    <img src={CompanyLogos} alt="Ambulytics Animation" width="900" style={{ maxWidth: "100%", height: "auto", margin: "10px auto" }} /> */}
                </div >
                <Offers />
                {/* <div style={{ backgroundColor: "#00c9ff1c", padding: "40px 0" }}>
                    <Offers />
                </div> */}
            </div>
        </MaterialUITheme>
    )
}
import { deviceDetect, getUA } from "react-device-detect";
import { useLocation } from 'react-router-dom';
import { AmbulyticsMarketingEventLogAPIService } from "./api-service";

const useCaptureEvent = () => {
    const location = useLocation();

    const captureEvent = (IPAddressTxt, EventLogTypeDsc, EventLogDsc) => {
        AmbulyticsMarketingEventLogAPIService.addAmbulyticsMarketingEventLog({
            IPAddressTxt,
            DeviceDsc: deviceDetect(getUA).isMobile ? `${deviceDetect(getUA).vendor} ${deviceDetect(getUA).model}` : `${deviceDetect(getUA).browserName} ${deviceDetect(getUA).osName}`,
            EventLogTypeDsc,
            EventLogDsc,
            EventPageUrlTxt: location.pathname
        })
    }

    return captureEvent
};

export default useCaptureEvent;
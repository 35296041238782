import React from 'react';
import { Box, Typography } from '@mui/material';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';

const DataDisplay = ({ titleOne, titleTwo, color, subTitleOne }) => {
    return (
        <Box sx={{ textAlign: "center", display: "inline-block", width: { xs: "80px", sm: "125px", xl: "135px" }, mx: { xs: 1, md: 2, xl: 3 }, my: 1 }}>
            <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                <Typography sx={{ color, fontSize: { xs: "20px", sm: "26px", md: "30px", xl: "34px" }, fontWeight: 300 }}>{titleOne}</Typography>
                {subTitleOne && <span style={{ fontSize: "16px", fontWeight: 300, color }}>({subTitleOne})</span>}
                {color === "#E96058" && <ArrowDropUpIcon fontSize='large' sx={{ color: "var(--ambulytics-green)" }} />}
            </Box>
            <Typography sx={{ fontSize: { xs: "16px", sm: "20px", md: "22px", xl: "24px" }, fontWeight: 300 }}>{titleTwo}</Typography>
        </Box>
    );
};

export default DataDisplay;
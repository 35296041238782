import React, { useState } from 'react';
import { Box, Slider, TextField, Typography } from '@mui/material';
import { useAppContext } from '../../context';
import useCaptureEvent from '../../config/useCaptureEvent';

const CenterChartComponent = () => {

    const [completionValue, setCompletionValue] = useState(75);
    const [daysWorked, setDaysWorked] = useState(23);
    const [visits, setVisits] = useState(97);
    const [wRVUs, setwRVUs] = useState(75);

    const { ip } = useAppContext()
    const captureEvent = useCaptureEvent()

    const handleCompletionValueChange = (event, newValue) => {
        setCompletionValue(newValue);
    };
    const handleDaysWorkedChange = (event, newValue) => {
        setDaysWorked(newValue);
    };
    const handleVisitsChange = (event, newValue) => {
        setVisits(newValue);
    };
    const handlewRVUs = (event, newValue) => {
        setwRVUs(newValue);
    };

    return (
        <Box>
            <Box sx={{ display: "inline-block" }}>
                <Box sx={{ display: "flex", flexDirection: "column", p: 1 }}>
                    <Typography sx={{ fontWeight: 300 }}>Completion</Typography>
                    <Box sx={{ display: "flex" }}>
                        <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
                            <Typography sx={{ fontSize: "14px", fontWeight: 300 }}>100</Typography>
                            <Typography sx={{ fontSize: "14px", fontWeight: 300 }}>80</Typography>
                            <Typography sx={{ fontSize: "14px", fontWeight: 300 }}>60</Typography>
                            <Typography sx={{ fontSize: "14px", fontWeight: 300 }}>40</Typography>
                            <Typography sx={{ fontSize: "14px", fontWeight: 300 }}>20</Typography>
                            <Typography sx={{ fontSize: "14px", fontWeight: 300 }}>0</Typography>
                        </Box>

                        <Box sx={{ height: 200, position: "relative" }}>
                            <Box sx={{ backgroundColor: "var(--ambulytics-green)", width: "85px", height: completionValue * 2, position: "absolute", bottom: 0, left: 4 }}>
                                {completionValue ? <Typography sx={{ color: "#fff", mt: `${(completionValue * 2 / 2) - 12}px` }}>{completionValue}%</Typography> : null}
                            </Box>
                        </Box>

                    </Box>

                    <Box sx={{ display: "flex", flexDirection: "column" }}>
                        <TextField value={completionValue} size="small" sx={{ mt: 1, width: "60px", "& .MuiInputBase-input": { py: "2px" }, "& .MuiOutlinedInput-root": { borderRadius: 0 } }} />
                        <Slider
                            // min={-20}
                            // max={100}
                            track={false}
                            value={completionValue}
                            onChange={handleCompletionValueChange}
                            onChangeCommitted={() => captureEvent(ip, "Changed Completion Value", `Set to ${completionValue}`)}
                            sx={{ width: "128px", color: "var(--ambulytics-green)", "& .MuiSlider-thumb": { borderRadius: 0, width: "5px" } }}
                        />
                    </Box>
                </Box>
            </Box>
            <Box sx={{ display: "inline-block" }}>
                <Box sx={{ display: "flex", flexDirection: "column", p: 1 }}>
                    <Typography sx={{ fontWeight: 300 }}>Days Worked/Month</Typography>
                    <Box sx={{ display: "flex" }}>
                        <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
                            <Typography sx={{ fontSize: "14px", fontWeight: 300 }}>30</Typography>
                            <Typography sx={{ fontSize: "14px", fontWeight: 300 }}>20</Typography>
                            <Typography sx={{ fontSize: "14px", fontWeight: 300 }}>10</Typography>
                            <Typography sx={{ fontSize: "14px", fontWeight: 300 }}>0</Typography>
                        </Box>

                        <Box sx={{ height: 200, position: "relative" }}>
                            <Box sx={{ backgroundColor: "var(--ambulytics-green)", width: "85px", height: daysWorked * 2 * 3.33, position: "absolute", bottom: 0, left: 4 }}>
                                {daysWorked ? <Typography sx={{ color: "#fff", mt: `${(daysWorked * 2 * 3.33 / 2) - 12}px` }}>{daysWorked}</Typography> : null}
                            </Box>
                        </Box>

                    </Box>

                    <Box sx={{ display: "flex", flexDirection: "column" }}>
                        <TextField value={daysWorked} size="small" sx={{ mt: 1, width: "60px", "& .MuiInputBase-input": { py: "2px" }, "& .MuiOutlinedInput-root": { borderRadius: 0 } }} />
                        <Slider
                            // min={-20}
                            max={30}
                            track={false}
                            value={daysWorked}
                            onChange={handleDaysWorkedChange}
                            onChangeCommitted={() => captureEvent(ip, "Changed Work Days", `Set to ${daysWorked}`)}
                            sx={{ width: "128px", color: "var(--ambulytics-green)", "& .MuiSlider-thumb": { borderRadius: 0, width: "5px" } }}
                        />
                    </Box>
                </Box>
            </Box>
            <Box sx={{ display: "inline-block" }}>
                <Box sx={{ display: "flex", flexDirection: "column", p: 1 }}>
                    <Typography sx={{ fontWeight: 300 }}>Visits/Day</Typography>
                    <Box sx={{ display: "flex" }}>
                        <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
                            <Typography sx={{ fontSize: "14px", fontWeight: 300 }}>10</Typography>
                            <Typography sx={{ fontSize: "14px", fontWeight: 300 }}>5</Typography>
                            <Typography sx={{ fontSize: "14px", fontWeight: 300 }}>0</Typography>
                        </Box>

                        <Box sx={{ height: 200, position: "relative" }}>
                            <Box sx={{ backgroundColor: "var(--ambulytics-green)", width: "85px", height: visits * 2, position: "absolute", bottom: 0, left: 4 }}>
                                {visits ? <Typography sx={{ color: "#fff", mt: `${(visits * 2 / 2) - 12}px` }}>{visits / 10}</Typography> : null}
                            </Box>
                        </Box>

                    </Box>

                    <Box sx={{ display: "flex", flexDirection: "column" }}>
                        <TextField value={visits / 10} size="small" sx={{ mt: 1, width: "60px", "& .MuiInputBase-input": { py: "2px" }, "& .MuiOutlinedInput-root": { borderRadius: 0 } }} />
                        <Slider
                            // min={-20}
                            // max={10}
                            track={false}
                            value={visits}
                            onChange={handleVisitsChange}
                            onChangeCommitted={() => captureEvent(ip, "Changed Visits", `Set to ${visits / 10}`)}
                            sx={{ width: "128px", color: "var(--ambulytics-green)", "& .MuiSlider-thumb": { borderRadius: 0, width: "5px" } }}
                        />
                    </Box>
                </Box>
            </Box>
            <Box sx={{ display: "inline-block" }}>
                <Box sx={{ display: "flex", flexDirection: "column", p: 1 }}>
                    <Typography sx={{ fontWeight: 300 }}>wRVUs/Visit</Typography>
                    <Box sx={{ display: "flex" }}>
                        <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
                            <Typography sx={{ fontSize: "14px", fontWeight: 300 }}>2</Typography>
                            <Typography sx={{ fontSize: "14px", fontWeight: 300 }}>1</Typography>
                            <Typography sx={{ fontSize: "14px", fontWeight: 300 }}>0</Typography>
                        </Box>

                        <Box sx={{ height: 200, position: "relative" }}>
                            <Box sx={{ backgroundColor: "var(--ambulytics-green)", width: "85px", height: wRVUs * 2, position: "absolute", bottom: 0, left: 4 }}>
                                {wRVUs ? <Typography sx={{ color: "#fff", mt: `${(wRVUs * 2 / 2) - 12}px` }}>{wRVUs / 50}</Typography> : null}
                            </Box>
                        </Box>

                    </Box>

                    <Box sx={{ display: "flex", flexDirection: "column" }}>
                        <TextField value={wRVUs / 50} size="small" sx={{ mt: 1, width: "60px", "& .MuiInputBase-input": { py: "2px" }, "& .MuiOutlinedInput-root": { borderRadius: 0 } }} />
                        <Slider
                            // min={-20}
                            // max={100}
                            track={false}
                            value={wRVUs}
                            onChange={handlewRVUs}
                            onChangeCommitted={() => captureEvent(ip, "Changed wRVUs", `Set to ${wRVUs / 50}`)}
                            sx={{ width: "128px", color: "var(--ambulytics-green)", "& .MuiSlider-thumb": { borderRadius: 0, width: "5px" } }}
                        />
                    </Box>
                </Box>
            </Box>
            <Box sx={{ display: "inline-block" }}>
                <Box sx={{ display: "flex", flexDirection: "column", p: 1 }}>
                    <Typography sx={{ fontWeight: 300 }}>Projected wRVUs</Typography>
                    <Box sx={{ display: "flex" }}>
                        <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
                            <Typography sx={{ fontSize: "14px", fontWeight: 300 }}>400K</Typography>
                            <Typography sx={{ fontSize: "14px", fontWeight: 300 }}>350K</Typography>
                            <Typography sx={{ fontSize: "14px", fontWeight: 300 }}>300K</Typography>
                            <Typography sx={{ fontSize: "14px", fontWeight: 300 }}>250K</Typography>
                            <Typography sx={{ fontSize: "14px", fontWeight: 300 }}>200K</Typography>
                            <Typography sx={{ fontSize: "14px", fontWeight: 300 }}>150K</Typography>
                            <Typography sx={{ fontSize: "14px", fontWeight: 300 }}>100K</Typography>
                            <Typography sx={{ fontSize: "14px", fontWeight: 300 }}>50K</Typography>
                            <Typography sx={{ fontSize: "14px", fontWeight: 300 }}>0K</Typography>
                        </Box>

                        <Box sx={{ height: 250, position: "relative" }}>
                            <Box sx={{ backgroundColor: "var(--ambulytics-green)", width: "85px", height: completionValue * 2.5, position: "absolute", bottom: 0, left: 4 }}>
                                {completionValue ? <Typography sx={{ color: "#fff", mt: `${(completionValue * 2.5 / 2) - 12}px` }}>{completionValue * 4}K</Typography> : null}
                            </Box>
                        </Box>

                    </Box>
                </Box>
            </Box>
        </Box>
    );
};

export default CenterChartComponent;
import React from 'react';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

export const options = {
    indexAxis: 'y',
    elements: {
        bar: {
            borderWidth: 2,
        },
    },
    responsive: true,
    plugins: {
        legend: {
            position: 'right',
            display: false
        },
        title: {
            display: false,
            text: 'wRVU Daily Average',
        },
    },
    scales: {
        y: {
            ticks: {
                color: "#01959E",
                font: {
                    // size: 18,
                },
                stepSize: 1,
                beginAtZero: true
            }
        },
        x: {
            ticks: {
                color: "#01959E",
                font: {
                    // size: 14
                },
                stepSize: 1,
                beginAtZero: true
            }
        }
    }
};

const labels = ['Mark Smit', 'Joe Smit', 'Lisa Sherry', 'Fred Flinstone', 'Mark Smit', 'Joe Smit', 'Lisa Sherry', 'Fred Flinstone', 'Mark Smit', 'Joe Smit', 'Lisa Sherry', 'Fred Flinstone'];

const datas = [90, 70, 60, 80, 30, 40, 70, 60, 80, 90, 60, 50]

export const data = {
    labels,
    datasets: [
        {
            label: 'Dataset 1',
            data: datas,
            backgroundColor: "#01959E"
        }
    ],
};

const RightChartComponent = () => {
    return (
        <Bar options={options} data={data} />
    );
};

export default RightChartComponent;
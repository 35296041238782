import React from 'react';
import ReactDOM from 'react-dom/client';
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import './app/config/style.css';
import RouterComponent from './app/config/router';
import AppProvider from './app/context';
import Footer from './app/components/footer';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <div className="App">
      <div style={{ flex: 1 }}>
        <AppProvider>
          <RouterComponent />
          <ToastContainer
            position="bottom-right"
            progress={undefined}
            theme="colored"
          />
        </AppProvider>
      </div>
      <Footer />
    </div>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
